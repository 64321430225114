.delete-confirm-popup {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    left: 0;
}


.delete-confirm-popup__container {
    position: absolute;
    background: #262637;

    max-width: 300px;
    width: 80vw;
    box-sizing: border-box;

    border-radius: 20px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.delete-confirm-popup__bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 1000vh;
    background-color: black;
    z-index: 99;
    opacity: 0.2;
}

.delete-confirm-popup__text {
    z-index: 101;

    text-align: center;
    color: #FFB31F;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 109.091% */
}

.delete-confirm-popup__button {
    z-index: 101;
    padding: 10px 20px;
    width: fit-content;
    text-transform: uppercase;

    border-radius: 12px;
    border: 2px solid #FFB31F;


    color: #FFB31F;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 109.091% */
}