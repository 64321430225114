.table-schema {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-rows: repeat(5, minmax(0, 1fr));
    grid-template-areas: 'table7 table6 table6 table5 table5 table4'
        'table8 . . . . table3'
        '. . . . . table2'
        '. . . . . table1'
        ' . table9 table10 table11 table11 .'
    ;
    column-gap: 8px;
    row-gap: 8px;
    padding: 45px 18px;
    width: 100%;
    max-width: unset;
    height: calc(100vh - 20px);
    box-sizing: border-box;

    border-radius: 20px;
    border: 1px solid #3C3C4A;

    pointer-events: none;
    user-select: none;
    overflow: hidden;
}

.table__box {
    width: 100%;

    /* min-width: 134px; */
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #8A8AA3;
    text-align: center;
    font-family: "Nunito Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    /* 111.111% */
    text-transform: uppercase;
    border-radius: 10px;
    /* border: 3px solid #8A8AA3; */
    background-color: #2B2B3E;

    padding: 24px 12px;
    box-sizing: border-box;
    position: relative;
    pointer-events: none;

    transition: all 1.5 linear;
}

.table__box__numbers {
    transition: color 1.5 linear;
}

.table__box__icons {
    display: flex;
    justify-content: start;
    /* width: 100%; */
    gap: 4px;

    position: absolute;
    top: 8px;
    right: 8px;
}

.table__box__icons__img {
    width: 20px;
    height: 20px;
}

@media (max-width:850px) {
    .table-schema {
        display: none;
    }
}