.notification-column {
    width: 100%;
    max-width: 360px;
    height: calc(100vh - 20px);
    padding: 0 10px 20px 10px;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;

    /* position: relative; */
    z-index: 9;
    padding-top: 87px;

    border-radius: 20px;
    background: #262637;
}

.notification-column__header {
    display: flex;
    justify-content: space-between;
    border-radius: 20px;
    /* background: transparent; */
    max-width: 360px;
    width: 100%;
    padding: 26px 12px 39px 12px;
    box-sizing: border-box;
    right: 10px;
    top: 10px;
    z-index: 16;
    position: fixed;
    background: #262637;
}

.notification-column__title {
    color: #B1B1C0;
    font-feature-settings: 'clig'off, 'liga'off;
    font-family: Mayak;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 111.111% */
    user-select: none;
}

.notification-column__header__img {
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.2;
    mix-blend-mode: lighten;
    width: 100%;
    /* width: 340px; */
    /* height: 590px; */
    border-radius: 20px;

    z-index: 17;
    pointer-events: none;
    user-select: none;
}

.notification-column__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    gap: 20px;
position: relative;
    z-index: 5;
}

.notification__box {
    width: 100%;
    height: 58px;
}

.notification-column__swipeable-card-content {
    width: 100%;
    box-sizing: border-box !important;
    height: 94px !important;

    position: relative;
    z-index: 15;
}

.notification-column_empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: auto;
    height: 640px;
}


@media (max-width:850px) {
    .notification-column {
        max-width: 100%;
    }

    .notification-column__header {
        max-width: calc(100% - 20px);
    }
}